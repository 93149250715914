import React, { Component } from "react";

import { Link } from "react-router-dom";
// import GoogleMaps from "simple-react-google-maps";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";

var bgimg1 = require("./../../images/main-slider/pink-slider1.jpg");
// var bgimg2 = require("./../../images/background/bg5.jpg");

class Hotel extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(" + bgimg1 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Hotel</h1>

                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"./"}>
                        <i className="fa fa-home"></i>
                      </Link>
                    </li>
                    <li>Hotel</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="career-text">
            {/* <div className="container text-center">
              <h3>For Job Applications Enquire us at: 0141 – 5166000</h3>
              <h5>OR</h5>
              <h3>
                Send your Resume at{" "}
                <a href="#" style={{ color: "#5fcac7" }}>
                  {" "}
                  hr@chokhidhani.com
                </a>
              </h3>
            </div> */}
          </div>

          <div className="career-box">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-4 col-md-4">
                  <div className="box-image">
                    <img
                      src={require("./../../images/background/attraction4.jpg")}
                      alt=""
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="box-image">
                    <img
                      src={require("./../../images/background/attraction3.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="box-text">
                    {/* <img
                      src={require("./../../images/product/marriage/marriage-8.jpg")}
                      alt=""
                    /> */}
                <h3 style={{marginLeft:"20px"}}>Hotel</h3>
             <p style={{marginLeft:"20px"}}>

             Pink Pearl Hotel is one of the best places to stay in Jaipur for its quality of service, comfortable rooms fully equipped with all modern amenities and an ambience offering holistic experience of appeasing hospitality.

 </p>
             <p style={{marginLeft:"20px"}}>
             Be welcomed to the world of wonderful activities as you stay with Pink Pearl. From Water Park in the premises to theme based Chokha Punjab, from numerous eateries and restaurants to discotheque, plan a staycation for all things rejuvenating under one roof.
</p>
 
<p style={{marginLeft:"20px"}}>
We have capacious rooms and we cater to small and big functions, meetings and other get-togethers.
</p>
 

                    {/* <h4>Price: 6000+ taxes</h4>
                    <Link to={"/contact-1"} className="btn btnhover">
                  Book Now
                </Link> */}

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/hotel1.jpeg")}
                      style={{height:"350px" , boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/hotel2.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/hotel3.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Hotel;
