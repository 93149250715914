import React, { Component } from "react";

import { Link } from "react-router-dom";
// import GoogleMaps from "simple-react-google-maps";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";

var bgimg1 = require("./../../images/main-slider/pink-slider1.jpg");
// var bgimg2 = require("./../../images/background/bg5.jpg");

class Thematic extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(" + bgimg1 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Thematic Areas</h1>

                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"./"}>
                        <i className="fa fa-home"></i>
                      </Link>
                    </li>
                    <li>Thematic Areas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="career-text">
            {/* <div className="container text-center">
              <h3>For Job Applications Enquire us at: 0141 – 5166000</h3>
              <h5>OR</h5>
              <h3>
                Send your Resume at{" "}
                <a href="#" style={{ color: "#5fcac7" }}>
                  {" "}
                  hr@chokhidhani.com
                </a>
              </h3>
            </div> */}
          </div>

          <div className="career-box">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-4 col-md-4">
                  <div className="box-image">
                    <img
                      src={require("./../../images/background/attraction4.jpg")}
                      alt=""
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="box-image">
                    <img
                      src={require("./../../images/thematic.jpg")}
                      style={{height:"350px" , boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="box-text">
                    {/* <img
                      src={require("./../../images/product/marriage/marriage-8.jpg")}
                      alt=""
                    /> */}
                <h3 style={{marginLeft:"20px"}}>Thematic Areas</h3>
             <p style={{marginLeft:"20px"}}>

             Pink Pearl is a name synonymous with fun and this is achieved through numerous theme based zones planned in every nook and cranny of this wonderful water park in Jaipur.

 </p>
             <p style={{marginLeft:"20px"}}>
Dedicated zones for super engaging and modern slides, pools, rides, and what not are aesthetically planned with vibrant colour scheme going all around to ensure nothing less than a fun filled, extremely amazing visit.
</p>
 
<p style={{marginLeft:"20px"}}>
Pink Pearl has a wave pool, a water dance zone, a dedicated small slides zone for kids, a long pool, a water slide pool and much more.
</p>
 
<p style={{marginLeft:"20px"}}>
So what are you waiting for, visit us today! 
                </p>
                    {/* <h4>Price: 6000+ taxes</h4>
                    <Link to={"/contact-1"} className="btn btnhover">
                  Book Now
                </Link> */}

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/thematic1.jpg")}
                      style={{height:"350px" , boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/thematic2.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/thematic3.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Thematic;
