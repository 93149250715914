import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  superLargeDesktop: {
   
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
	autoPlay:true,
	loop:true
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
	autoPlay:true,
	loop:true
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
	autoPlay:true
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
	autoPlay:true
  }
};

class Owl extends Component{		
	render(){
		return(
			<div class="section-full related-products content-inner bg-gray-light">
				<div class="container">
				
					<div class="products-carousel">
						<Carousel 
							autoPlay={this.props.deviceType !== "mobile" ? true : false}
							responsive={responsive}
							arrows={false}							
						>
							<div className="p-a15">
								<div class="item-box shop-item">
									<div class="item-img">
										<img src={require("./../../images/waterpark6.jpg")} alt="" />
										{/* <div class="price">
											$35.00 
										</div> */}
									</div>
									<div class="item-info text-center">
										{/* <h4 class="item-title"><Link to={'/shop-product-details'}>Pancakes</Link></h4> */}
										{/* <Link to={'/shop-product-details'} class="btn btnhover"><i class="ti-shopping-cart m-r5"></i> Add To Cart</Link> */}
									</div>
								</div>
							
							
							</div>

							<div className="">
								<div class="item-box shop-item">
									<div class="item-img">
										<img src={require("./../../images/waterpark2.jpg")} alt="" />
										{/* <div class="price">
											$35.00 
										</div> */}
									</div>
									<div class="item-info text-center">
										{/* <h4 class="item-title"><Link to={'/shop-product-details'}>Pancakes</Link></h4> */}
										{/* <Link to={'/shop-product-details'} class="btn btnhover"><i class="ti-shopping-cart m-r5"></i> Add To Cart</Link> */}
									</div>
								</div>
							
							
							</div>


							<div className="">
								<div class="item-box shop-item">
									<div class="item-img">
										<img src={require("./../../images/waterpark3.jpg")} alt="" />
										{/* <div class="price">
											$35.00 
										</div> */}
									</div>
									<div class="item-info text-center">
										{/* <h4 class="item-title"><Link to={'/shop-product-details'}>Pancakes</Link></h4> */}
										{/* <Link to={'/shop-product-details'} class="btn btnhover"><i class="ti-shopping-cart m-r5"></i> Add To Cart</Link> */}
									</div>
								</div>
							
							
							</div>


							<div className="">
								<div class="item-box shop-item">
									<div class="item-img">
										<img src={require("./../../images/waterpark4.jpg")} alt="" />
										{/* <div class="price">
											$35.00 
										</div> */}
									</div>
									<div class="item-info text-center">
										{/* <h4 class="item-title"><Link to={'/shop-product-details'}>Pancakes</Link></h4> */}
										{/* <Link to={'/shop-product-details'} class="btn btnhover"><i class="ti-shopping-cart m-r5"></i> Add To Cart</Link> */}
									</div>
								</div>
							
							
							</div>


							<div className="">
								<div class="item-box shop-item">
									<div class="item-img">
										<img src={require("./../../images/waterpark5.JPG")} alt="" />
										{/* <div class="price">
											$35.00 
										</div> */}
									</div>
									<div class="item-info text-center">
										{/* <h4 class="item-title"><Link to={'/shop-product-details'}>Pancakes</Link></h4> */}
										{/* <Link to={'/shop-product-details'} class="btn btnhover"><i class="ti-shopping-cart m-r5"></i> Add To Cart</Link> */}
									</div>
								</div>
							
							
							</div>


							
							
							
						</Carousel>
					</div>
				</div>		
			</div>	
		);	
	}	
}		

export default Owl;