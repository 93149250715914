import React, { Component } from "react";

import { Link } from "react-router-dom";
// import GoogleMaps from "simple-react-google-maps";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Owl from "../Element/Owl";

var bgimg1 = require("./../../images/main-slider/pink-slider1.jpg");
// var bgimg2 = require("./../../images/background/bg5.jpg");

class Waterpark extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(" + bgimg1 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Waterpark</h1>

                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"./"}>
                        <i className="fa fa-home"></i>
                      </Link>
                    </li>
                    <li>Waterpark</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>






          <div className="career-text">
            {/* <div className="container text-center">
              <h3>For Job Applications Enquire us at: 0141 – 5166000</h3>
              <h5>OR</h5>
              <h3>
                Send your Resume at{" "}
                <a href="#" style={{ color: "#5fcac7" }}>
                  {" "}
                  hr@chokhidhani.com
                </a>
              </h3>
            </div> */}
          </div>

          <div className="career-box">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-4 col-md-4">
                  <div className="box-image">
                    <img
                      src={require("./../../images/background/attraction4.jpg")}
                      alt=""
                    />
                  </div>
                </div> */}
               
              
               <h3 style={{ textAlign:"center"}}>Let the water splashes bring happiness in abundance while you have fun at Pink Pearl Fun City and Water Park.</h3>
              <div className="container mb-5">
                <div className="row m-lr0 about-area1">
                    
                  <div className="col-lg-3 p-lr0">
                  <video autoPlay muted loop style={{ width: "100%", maxHeight:"600px" }}>
                <source
                  src={require("./../../images/waterpark.MP4")}
                  type="video/mp4"
                />
              </video>  
                  </div>
                  <div className="col-lg-6 p-lr0 d-flex align-items-center text-center">
                    <div className="about-bx">
                      <div className="section-head text-center text-white">
                        <h4 className="text-white">Timings</h4>
                        {/* <p>Wedding Cake Sale !</p> */}
                        {/* <div className="icon-bx">
                          <img
                            // src={require("./../../images/icons/service-icon/icon2.png")}
                            alt=""
                          />
                        </div> */}
                      </div>
                      <p>
                        <strong> Timing: 10:30 AM to 7:30 PM </strong>
                      </p>
                      <p>
                        Monday to Friday - Adults Rs
                        500 
                      </p>
                      <p>
                      Saturday - Sunday - Adults Rs 600
                      </p>
                      <p>Monday to Friday - Kids Rs 350 ( Height 4.5 ft to 3.25 ft )</p>
                      <p>Saturday - Sunday - Kids Rs 400 ( Height 4.5 ft to 3.25 ft )</p>
                      
                      <p>Note:- Costumes and lockers extra charges ( Optional )</p>

                      <h3 style={{marginTop:"20px", color:"#fff"}}>Contact Us:<a href="tel:+91-9314823132" style={{color:"#fff"}}>+91-9314823132</a></h3>
                      
                      <Link
                        to={"/contact-1"}
                        className="btn-secondry white btnhover btn-md"
                      >
                        <i className="fa fa-cart"></i>GET NOW
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 p-lr0">
                  <video autoPlay muted loop style={{ width: "100%", maxHeight:"600px" }}>
                <source
                  src={require("./../../images/waterpark.MP4")}
                  type="video/mp4"
                />
              </video>  
                  </div>
                </div>
              </div>
                
              </div>

              <div className="row">
                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/waterpark2.jpg")}
                      style={{height:"350px" , boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/waterpark3.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/waterpark4.jpg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Waterpark;
