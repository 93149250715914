import React, { Component } from "react";

import { Link } from "react-router-dom";
// import GoogleMaps from "simple-react-google-maps";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";

var bgimg1 = require("./../../images/main-slider/pink-slider1.jpg");
// var bgimg2 = require("./../../images/background/bg5.jpg");

class Chokha extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(" + bgimg1 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Chokha Punjab</h1>

                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"./"}>
                        <i className="fa fa-home"></i>
                      </Link>
                    </li>
                    <li>Chokha Punjab</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="career-text">
            {/* <div className="container text-center">
              <h3>For Job Applications Enquire us at: 0141 – 5166000</h3>
              <h5>OR</h5>
              <h3>
                Send your Resume at{" "}
                <a href="#" style={{ color: "#5fcac7" }}>
                  {" "}
                  hr@chokhidhani.com
                </a>
              </h3>
            </div> */}
          </div>

          <div className="career-box">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-4 col-md-4">
                  <div className="box-image">
                    <img
                      src={require("./../../images/background/attraction4.jpg")}
                      alt=""
                    />
                  </div>
                </div> */}
                <div className="col-lg-12 col-md-12">
                  <div className="box-image">
                    <img
                      src={require("./../../images/a1.png")}
                      style={{height:"450px"}}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="box-text">
                    {/* <img
                      src={require("./../../images/product/marriage/marriage-8.jpg")}
                      alt=""
                    /> */}
                <h3 style={{marginLeft:"20px"}}>Chokha Punjab</h3>
             <p style={{marginLeft:"20px"}}>

             "धरती धौंरा री" दे विच पंजाब दी सौंधी सौंधी खुशबू"

 </p>
             <p style={{marginLeft:"20px"}}>
             Chokha Punjab is a thematic ethnic place depicting the village life of Punjab here in Jaipur, Rajasthan. With the Punjabi theme decor, enthusiastic activities, delectable selection of Punjabi Food, we tend to give an honest to goodness experience.
</p>
 
<p style={{marginLeft:"20px"}}>
It's ideal to spend an evening full of fun with loved ones wherein you not only enjoy scrumptious food but also an extravaganza of fun.
</p>
 
<p style={{marginLeft:"20px"}}>
Chokha Punjab is a concept brought to you by the renowned hospitality brand, Chokhi Dhani Group, as an attempt to promote the diversified Indian culture hailing from different parts of the country and what better than the vivacious state of Punjab.
                </p>
                <p style={{marginLeft:"20px"}}>
                With a team of dedicated staff and numerous activities, we await your presence.  </p>
                    {/* <h4>Price: 6000+ taxes</h4>
                    <Link to={"/contact-1"} className="btn btnhover">
                  Book Now
                </Link> */}

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/choka1.jpeg")}
                      style={{height:"350px" , boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/chokha2.jpeg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-4">
                <div className="box-image">
                    <img
                      src={require("./../../images/chokha3.jpeg")}
                      style={{height:"350px", boxShadow:"10px 10px 5px #ccc"}}
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Chokha;
